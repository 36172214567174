import { useTranslation } from 'react-i18next';

function OrdersTable(props:any) {
  const {t} =useTranslation()
  const {items,title}=props
  return (
    <div className='row p-5'>
        <hr/>
        <div className='table-responsive'>
            <h2 className='fw-bold'>{title}</h2>
        <table
            id='kt_table_users'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
        >
        <thead>
        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
            <th>#</th>
            <th>{t('User')}</th>
            <th>{t('Laundry')}</th>
            {/* <th>{t('Order Type')}</th> */}
            <th>{t('Status')}</th>
            <th>{t('Created At')}</th>
        </tr>
        </thead>
            <tbody className='text-gray-600 fw-bold' >
                {items?.length > 0 ?(
                    items?.map((item:any,index:any)=>(
                    <tr key={index}>
                        <td>{item?.item_number}</td>
                        <td>{item?.user_name?item?.user_name:'---'}</td>
                        <td>{item?.provider_name?item?.provider_name:'---'}</td>
                        {/* <td>{item?.order_type==1?t('Normal'):t('Special')}</td> */}
                        <td>
                            {
                                item?.status==1? <label className='fs-5 text-warning mb-0' htmlFor='typeArabicName'>{t('Pending')}</label>:
                                item?.status==2? <label className='fs-5 text-success mb-0' htmlFor='typeArabicName'>{t('Processing')}</label>:
                                item?.status==3? <label className='fs-5 text-danger mb-0' htmlFor='typeArabicName'>{t('Out Of Delivery')}</label>:
                                item?.status==4? <label className='fs-5 text-secondary mb-0' htmlFor='typeArabicName'>{t('Delivered')}</label>:
                                item?.status==5? <label className='fs-5 text-info mb-0' htmlFor='typeArabicName'>{t('Return')}</label>:
                                ''
                            }
                        </td>
                        <td>{item?.created_at?item?.created_at:'---'}</td>
                    </tr>
                ))
                ) : (
                <tr>
                    <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        {t('No matching records found')}
                    </div>
                    </td>
                </tr>
            )}
            </tbody>
            </table>
        </div>
    </div>
  )
}

export default OrdersTable