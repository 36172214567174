// import Payment from "./PaymentForm/Payment"
import { useEffect ,useState} from 'react'

import { useParams, useSearchParams, useNavigate } from "react-router-dom"
import { axiosConfig } from "utils/axiosConfig"

function CompleteOnlineOrderPage() {
  const params = useParams()
  const navigate=useNavigate()
  const [searchParams ,setSearchParams] = useSearchParams()
  const [paymentStatusLoading ,setPaymentStatusLoading] = useState(false)
  const [paymentStatusLoadingFailed ,setPaymentStatusLoadingFailed] = useState(false)
  function completeOnlineOrder(){
    // setIsLoading(true)
    axiosConfig.post(`/orders/complete-online-order/${params?.id}`,{charge_id:searchParams.get('tap_id')},{
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
    }).then(res=>{
      setSearchParams({'complete_order_status':res?.data?.data?.complete_order_status})
      // window.location.replace(`?complete_order_status=${res?.data?.data?.complete_order_status}`)
      setPaymentStatusLoading(true)
      // navigate(`orders?complete_order_status=${res?.data?.data?.complete_order_status}`)
      // window.close()
      // setTimeout(() => {
      //   window.close();
      // }, 1000);
    }).catch(err=>{
      // setIsLoading(false)
      setPaymentStatusLoadingFailed(true)
      console.log(err)
    })
  }
  useEffect(()=>{
    completeOnlineOrder()
  },[])
  return (
    <>
    {paymentStatusLoading==true?'Paid Sussessfully':'Processing'}
    {paymentStatusLoadingFailed==true?'Paid Error':'Processing'}
    </>
  )
}

export default CompleteOnlineOrderPage