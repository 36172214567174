import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

// const FirebaseConfig = () => {

  const firebaseConfigVar = {
    apiKey: "AIzaSyBvXFqfn4TAngI_7YxXSPAlthGD0DOm8WM",
    authDomain: "safri-c7aa3.firebaseapp.com",
    projectId: "safri-c7aa3",
    storageBucket: "safri-c7aa3.appspot.com",
    messagingSenderId: "523296260220",
    appId: "1:523296260220:web:27c8acf4646f5ed4bc09f9",
    measurementId: "G-TYNML3Q4MH"
  };
  

  const app = initializeApp(firebaseConfigVar);
  const messaging = getMessaging(app);

  export const requestPermission =()=>{
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        return getToken(messaging,{
          vapidKey:'BD4P2JWwSXLhcSBOEY9DcExyirDa6rhaKvoVsLyZrfWl5ggJgiGOh-dhene7zEsrpboms5OHLnOhkIZGZ-X8L0c'
        })
          .then((token) => {
            console.log('FCM Token:', token);
          })
          .catch((error) => {
            console.error('Error obtaining FCM token:', error);
          });
      } else {
        console.warn('Notification permission denied');
      }
    });
  }
  // requestPermission()

  export const onMessageLisener =()=>{
    return new Promise((resolve,reject)=>{
      onMessage(messaging, (payload) => {
        console.log('Message received:', payload);
        resolve(payload); // Resolve the promise with the payload
      }, (error) => {
        console.log('Error:', error);
        reject(error); // Reject the promise if there's an error
      });
    })
  }
// }
// export default FirebaseConfig
